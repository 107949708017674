<template>
  <layout-admin-dashboard-view
    :show-toolbar="false">
    <!-- { name: 'entity-list', params: { entityType: entityType } } -->
    <template #title>
      {{ translateTitleCase("entity.crud.createEntityTitle") }}
      <strong>{{ t(`entity.${entityType}.name`) }}</strong>
    </template>

    <template #default>
      <crud-create
        :form-path="'entity/' + entityType + '/create'"
        :form-action="'entity/' + entityType"
        :success-redirect="{
          name: 'entity-list',
          params: { entityType: entityType },
        }"
        :entity-type="entityType">
      </crud-create>
    </template>
  </layout-admin-dashboard-view>
</template>

<script>
import CrudCreate from "@/client/components/crud/Create.vue";

export default {
  name: "EntityCreate",
  components: { CrudCreate },
  props: {
    entityType: {
      type: String,
      default: "default",
    },
  },
  data: function () {
    return {
      entity: {},
    };
  },
  computed: {
    formPath() {
      return "entity/" + this.entityType + "/create";
    },
    formAction() {
      return "entity/" + this.entityType;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss"></style>
